import React, { useEffect, useState } from 'react';
import './Membership.css';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Helmet } from 'react-helmet';
import cover from '../assets/shop_skincare_cover.png';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Membership: React.FC = () => {
  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [coverDescription, setCoverDescription] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [stripeId, setStripeId] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const fetchCoverData = async () => {
      try {
        const coverCollection = collection(db, 'cover');
        const coverQuery = query(coverCollection, where('page_name', '==', 'membership'));
        const coverSnapshot = await getDocs(coverQuery);

        coverSnapshot.forEach(doc => {
          const data = doc.data();
          setCoverImage(data.cover);
          setCoverDescription(data.description);
        });
      } catch (error) {
        console.error('Error fetching cover data: ', error);
      }
    };

    const listenToAuthChanges = () => {
      const auth = getAuth();

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setIsLoggedIn(true);
          try {
            const userRef = doc(db, 'users', user.uid); // Reference to the user's document
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setUserEmail(userData?.email || null);
              setStripeId(userData?.stripe_id || null);
            }
          } catch (error) {
            console.error('Error fetching user data: ', error);
          }
        } else {
          setIsLoggedIn(false);
          setUserEmail(null);
          setStripeId(null);
        }
      });
    };

    fetchCoverData();
    listenToAuthChanges();
  }, []);

  const handleRedirect = () => {
    window.location.href = '/login';
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Membership | QD Skinnovations</title>
        <meta name="description" content="Join VIP Membership" />
        <meta name="keywords" content="VIP, Membership, Join VIP, Join Membership" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
        <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
        <link rel="canonical" href="https://qdskinnovations.com/membership" />
        {/* Load Stripe Pricing Table script */}
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </Helmet>

      {coverImage ? (
        <img src={coverImage} alt="Cover" className="cover-image" />
      ) : (
        <img src={cover} alt="Cover" className="cover-image" />
      )}

      <div className="paragraph-box">
        <p>{coverDescription || ''}</p>
      </div>

      <div className="membership-container">
        {/* Conditionally render the pricing table */}
        {isLoggedIn ? (
          <stripe-pricing-table
            pricing-table-id="prctbl_1QMgUmGpKIvAqfAFRtLib95f"
            publishable-key="pk_test_51QJrZgGpKIvAqfAF6aTyZwAIzNmVfUx0Jmcu5Vfe6Gj5lzxmHp9L9WlRlDYzUNOJCpunsYLKF33E102IMO6iFHYl004llHjxpQ"
            client-reference-id={stripeId || ''}
            customer-email={userEmail || ''}
          />
        ) : (
          <div className="overlay-content">
            <p>Please log in to view subscription options.</p>
            <button onClick={handleRedirect} className="overlay-button">
              Login to Subscribe
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Membership;
